<template>
  <div>
    <h2>ダッシュボード(求人企業用)</h2>
    <b-row class="match-height">
      <b-col lg="6">
        <ApexDonutChart />
      </b-col>
      <b-col lg="6">
        <ApexRadarChart />
      </b-col>
      <b-col lg="12">
        <ApexDataBarChart />
      </b-col>
      <b-col lg="12">
        <ApexLineChart />
      </b-col>
      <b-col lg="12">
        <ApexLineAreaChart />
      </b-col>
      <b-col lg="12">
        <h2 class="mb-2">
          注目の求職者
        </h2>
        <SearchTable />
      </b-col>
      <b-col lg="12">
        <FavoriteList />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import SearchTable from '@/components/company/seachJobSeeker/SearchTable.vue'
import ApexDataBarChart from '@/components/ui/analytics/company/ApexDataBarChart.vue'
import ApexLineChart from '@/components/ui/analytics/company/ApexLineChart.vue'
import ApexLineAreaChart from '@/components/ui/analytics/company/ApexLineAreaChart.vue'
// import EchartDoughnut from '@/components/ui/analytics/company/EchartDoughnut.vue'
// import EchartRadar from '@/components/ui/analytics/company/EchartRadar.vue'
import ApexDonutChart from '@/components/ui/analytics/company/ApexDonutChart.vue'
import ApexRadarChart from '@/components/ui/analytics/company/ApexRadarChart.vue'
import FavoriteList from '@/components/company/favorite/FavoriteList.vue'

export default {
  components: {
    BRow,
    BCol,
    SearchTable,
    ApexDataBarChart,
    ApexLineChart,
    ApexLineAreaChart,
    ApexDonutChart,
    ApexRadarChart,
    FavoriteList,
    // EchartDoughnut,
    // EchartRadar,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
