<template>
  <b-card title="データ2(仮題)">
    <b-card-sub-title class="mb-2">
      データを表示しています。
    </b-card-sub-title>
    <vue-apex-charts
      type="radar"
      height="400"
      :options="apexChatData.radarChart.chartOptions"
      :series="apexChatData.radarChart.series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    BCardSubTitle,
    VueApexCharts,
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
